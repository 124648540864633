import Notifications from './Notifications'

import UserStore from './../stores/User';

var nocache      = require('./superagent-no-cache-cw.jsx');
var request      = require('superagent');

var update       = require('react-addons-update');

const defaultOptions = {
    endpoint: null,
    data: null,
    callback: null,
    headers: {
        'Authorization': 'Bearer ' + UserStore.getJwt()
    }
}

var ApiClient = {

    getPostRequestObject: function ( url, options, jwt ) {
        if ( !options ) {
            options = {};
        }
        options.endpoint = url;
        options = update(defaultOptions, {$merge: options });
        options.headers.Authorization = 'Bearer ' + ( jwt ? jwt : UserStore.getJwt() );

        // Append API root if defined
        options = this._prepareUrls(options);

        return request.post(options.endpoint)

            .set ( options.headers );
    },

    /**
     * Return a GET request object Using ES6 promises
     * @param type
     * @param options
     */
    get: function ( options, jwt ) {

        options = update(defaultOptions, {$merge: options });
        options.headers.Authorization = 'Bearer ' + ( jwt ? jwt : UserStore.getJwt() );

        // Append API root if defined
        options = this._prepareUrls(options);

        return request.get( options.endpoint )
            .accept('json')
            .type('json')

            .set ( options.headers )
            .query ( options.query );
    },

    post: function ( options, jwt ) {

        options = update(defaultOptions, {$merge: options });
        options.headers.Authorization = 'Bearer ' + ( jwt ? jwt : UserStore.getJwt() );

        // Append API root if defined
        options = this._prepareUrls(options);

        return request.post( options.endpoint )
            .accept('json')
            .type('json')

            .set ( options.headers )
            .query ( options.query )
            .send( options.data );
    },

    patch: function ( options, jwt ) {

        options = update(defaultOptions, {$merge: options });
        options.headers.Authorization = 'Bearer ' + ( jwt ? jwt : UserStore.getJwt() );

        // Append API root if defined
        options = this._prepareUrls(options);

        return request.patch( options.endpoint )
            .accept('json')
            .type('json')

            .set ( options.headers )
            .query ( options.query )
            .send( options.data );
    },

    put: function ( options, jwt ) {

        options = update(defaultOptions, {$merge: options });
        options.headers.Authorization = 'Bearer ' + ( jwt ? jwt : UserStore.getJwt() );

        // Append API root if defined
        options = this._prepareUrls(options);

        return request.put( options.endpoint )
            .accept('json')
            .type('json')

            .set ( options.headers )
            .query ( options.query )
            .send( options.data );
    },

    delete: function ( options, jwt ) {

        options = update(defaultOptions, {$merge: options });
        options.headers.Authorization = 'Bearer ' + ( jwt ? jwt : UserStore.getJwt() );

        // Append API root if defined
        options = this._prepareUrls(options);

        return request.delete( options.endpoint )
            .accept('json')
            .type('json')

            .set ( options.headers )
            .query ( options.query );
    },

    onError: function(err) {

        if ( err ) {

            var res = err.response ? err.response : [];

            var error = ''
                + ( res && res.error ? res.error : 'API error' );
            if ( res && res.body && res.body.error ) {
                error = res.body.error;
            }

            if (typeof error === 'string') {
                error = error.replace(/\n/g, '<br/>');
            }

            Notifications.showNotific8(error, 'danger', { closeInSeconds: 6 } );
        }
    },

    _prepareUrls(options) {

        if ( process.env.REACT_APP_DEVELOPMENT_API_ROOT && options.endpoint ) {
            options.endpoint = process.env.REACT_APP_DEVELOPMENT_API_ROOT + options.endpoint;
        }

        return options;
    }
}


export default ApiClient;
