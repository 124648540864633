import React from 'react';
import { Link } from 'react-router-dom';
import AdminAreaRouter from './AdminAreaRouter'

export default class NavbarOwn extends React.Component {

    render() {

        const {appConfig} = this.props;

        return (
            <div id="sidebar-wrapper">
                <nav id="main-navigation">
                    <div className="sidebar-header">

                            <Link to="/">
                                {appConfig.site_logo ?<img src={appConfig.site_logo} title={appConfig.site_name} className="img-responsive" /> : null }
                                <span>{appConfig.site_name}</span>
                            </Link>

                    </div>

                    <ul className="sidebar-nav nav">
                        <li>
                            <Link to="/dashboard">
                                <i className="fal fa-tachometer-alt fa-fw marginR half"/> Dashboard
                            </Link>
                        </li>

                        <AdminAreaRouter>
                        <li>
                            <Link to="/users">
                                <i className="fal fa-user fa-fw marginR half"/> Clients
                            </Link>
                        </li>
                        </AdminAreaRouter>

                        <li>
                            <a href="#">
                                <i className="fal fa-comments-alt fa-fw marginR half"/> Accounts
                            </a>

                            <ul className="show sidebar-submenu" id="accountsSubmenu">
                                <li>
                                    <Link to="/listeners">
                                        {/*<i className="fal fa-head-side-headphones fa-fw marginR half"/>*/} Listeners
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/subscriptions">
                                        {/*<i className="fal fa-microphone-alt fa-fw marginR half"/> */}Remotes
                                    </Link>
                                </li>

                                <AdminAreaRouter>
                                <li>
                                    <Link to="/subscriptions/attributes">
                                        {/*<i className="fal fa-microphone-alt fa-fw marginR half"/> */}Attributes
                                    </Link>
                                </li>
                                </AdminAreaRouter>
                            </ul>
                        </li>

                        <li>
                            <Link to="/storage/recordings">
                                <i className="fal fa-cassette-tape fa-fw marginR half"/> Recordings
                            </Link>
                        </li>

                        {/*<li>
                            <a href="javascript:;">
                                <i className="fal fa-cassette-tape fa-fw marginR half"/> Recordings
                            </a>

                            <ul className="show sidebar-submenu" id="recordingsSubmenu">
                                <li>
                                    <Link to="/storage/recordings">
                                        Audio
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/storage/video">
                                        Video
                                    </Link>
                                </li>

                            </ul>

                        </li>*/}

                        <AdminAreaRouter>
                            <li>
                                <a href="#"><i className="fal fa-shield fa-fw marginR half"/>  Audit</a>
                                <ul className="show sidebar-submenu" id="systemSubmenu">
                                    <li>
                                        <Link to="/audit/event-log">
                                            Events
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/audit/activity">
                                            Activity
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/audit/api-log">
                                            API access
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </AdminAreaRouter>


                        <AdminAreaRouter>
                            <li>
                                <Link to="/debuglog">
                                    <i className="fal fa-debug  fa-fw marginR half"/> Application logs
                                </Link>
                            </li>
                        </AdminAreaRouter>


                       {/* <AdminAreaRouter>
                            <li>
                                <Link to="/system/settings">
                                    <i className="fal fa-wrench fa-fw marginR half"/> Configuration
                                </Link>
                            </li>
                        </AdminAreaRouter>*/}

                        <AdminAreaRouter>
                            <li>
                                <a href="#"><i className="fal fa-wrench fa-fw marginR half"/>  System</a>
                                <ul className="show sidebar-submenu" id="systemSubmenu">
                                    <li>
                                        <Link to="/system/settings">
                                            Settings
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </AdminAreaRouter>
                    </ul>
                </nav>
            </div>
        )
    }
}
