import React from 'react'
import classnames from "classnames"
import ReactPlayer from 'react-player'

import _ from "lodash"

import Card from "react-bootstrap/Card";
import RecordingsStore from "../../common/stores/Recordings";
import moment from "moment-timezone";
import UserStore from "../../common/stores/User";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import bootbox from "bootbox";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
    formatTime,
    formatFileSize,
    secondsToDuration,
} from '../../common/lib/helper';
import RecordingRegions from "./recording-regions";
import Notifications from "../../common/lib/Notifications";
import RecordingParticipants from './participants';
import IconHoverTooltip from '../../common/components/IconHoverTooltip';
import LocationMap from './location-map';



class VideoRow extends React.Component {

    constructor(props) {
        super(props);

        this.handleDelete = this.handleDelete.bind(this)
        this.handleDownload = this.handleDownload.bind(this)
        this.handleVolume = this.handleVolume.bind(this)

        this.prepareFile = this.prepareFile.bind(this)
        this.pause = this.pause.bind(this)
        this.play = this.play.bind(this)

        this.playerRef  = React.createRef();
        this.volumeInputRef = React.createRef();

        // Prepare regions from parent

        this.state = {

            muted: false,
            mediaUrl: null,

            showDetailsScreen: false,
            showPlayButton: true,
            streamUrl: null,
            volume: 1,
            zoom: 0,
            paused: true,
            played: false,
            meta: {
                width: 640,
                height: 360,
            }
        }
    }

    componentDidMount() {
        this.prepareFile().then(() => {})
    }

    async handleDownload() {
        const {id} = this.props;
        const mediaData = await RecordingsStore.getDownloadUrl(id)

        if (!mediaData || !mediaData.stream_url) {
            // Some error occurred while decrypting the file maybe?
            Notifications.showNotific8('An unexpected error has occurred while preparing ' +
                'and decrypting the file. Please try again and contact support if the problem persists.',
                'danger', { closeInSeconds: 8 } );

            return;
        }

        const downloadUrl = mediaData.download_url;

        window.open(downloadUrl, '_blank')
    }

    async prepareFile() {
        const {
            id,
        } = this.props;

        const mediaData = await RecordingsStore.getDownloadUrl(id);

        if (!mediaData || !mediaData.stream_url) {
            // Some error occurred while decrypting the file maybe?
            Notifications.showNotific8('An unexpected error has occurred while preparing ' +
                'and decrypting the file. Please try again and contact support if the problem persists.',
                'danger', { closeInSeconds: 8 } );

            return;
        }

        // Set the state
        this.setState({
            mediaUrl: mediaData.download_url,
            streamUrl: mediaData.stream_url,
            showPlayButton: false,
        }, () => {
            // Load video metadata
            /*let video = document.createElement('video');

            // place a listener on it
            video.addEventListener( "loadedmetadata", (ev) => {
                // retrieve dimensions
                let height = (ev.target && ev.target.videoHeight) || 360;
                let width = (ev.target && ev.target.videoWidth) || 640;

                // send back result
                this.setState({
                    meta: {
                        height : height,
                        width : width
                    }
                });
            }, false );

            // start download meta-datas
            video.src = mediaData.stream_url;*/
        });

        return mediaData;
    }

    handleDelete() {
        const {
            data
        } = this.props;

        const subscription = data.subscription.data;

        let title = (subscription.display_name ? subscription.display_name : subscription.sip_username );
        title += `&nbsp;&nbsp;<span style="font-weight: 300;">${data.file_name}</span> `;

        bootbox.confirm({
            title: title,
            message: "Are you sure you want to delete this recording?",
            callback: function(result) {
                if ( result ) {
                    this.props.onDelete(data.id)
                }

            }.bind(this),

            buttons: {
                cancel: {
                    label: 'Cancel',
                    className: 'btn-default'
                },
                confirm: {
                    label: 'Delete',
                    className: 'btn-danger'
                }
            }
        });
    }

    handleVolume(event) {
        let {
            value
        } = event.target;

        if (value<0) {
            value = 0;
        }

        if (value>100) {
            value = 100;
        }

        this.setState({
            volume: value,

        }, () => {
            //this.wavesurfer && this.wavesurfer.setVolume(Number(value/100))
        })
    }

    handleMuteUnmute(event) {
        const {
            muted,
            volume,
        } = this.state;

        this.setState({
            muted: !muted
        })
    }

    pause() {
        this.setState({
            paused: true,
            showPlayButton: true,
        })
    }

    play() {
        this.setState({
            paused: false,
            played: true,
            showPlayButton: false,
        }, () => {

        })
    }

    toggleParticipants() {
        const {showDetailsScreen} = this.state;

        this.setState({
            showDetailsScreen: !showDetailsScreen,
        })
    }

    toggleLocationMap() {
        const {showDetailsScreen} = this.state;

        this.setState({
            showDetailsScreen: !showDetailsScreen,
        })
    }

    playerRef = player => {
        console.log(player)
        this.player = player
    }

    render() {
        const {data} = this.props;
        const {
            showDetailsScreen,
            streamUrl,
        } = this.state;

        const subscription = data.subscription.data;

        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        const c = moment.unix(data.recorded_at).tz(timezone);

        let participants_count = 0;
        if (data.participants && data.participants.users) {
            participants_count = data.participants.users.length;
        }

        let location_enabled = !!data.latitude;

        return (
            <div className="recording-row video-row">
                <div className="recording-meta">

                    <div className="meta-play">
                        <div className="d-flex justify-content-center align-items-center h-100">
                            {this.state.paused ?
                              <button className="btn btn-warning video-player-button play"
                                      onClick={() => this.play()}
                              >
                                  <i className="fa fa-play"/>
                              </button>
                              :
                              <button className="btn btn-warning video-player-button pause"
                                      onClick={() => this.pause()}
                              >
                                  <i className="fa fa-pause"/>
                              </button>
                            }
                        </div>
                    </div>

                    <div className="meta-info">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={(props) => {
                              return (<Tooltip {..._.omit(props, ['show'])}>{formatTime(data.recorded_at, timezone)}</Tooltip>)
                          }}
                        >
                            <div className="meta-box full-width text-primary">
                                {c.format('D MMM YYYY')}
                            </div>
                        </OverlayTrigger>
                    </div>

                    <div className="meta-info">

                        <div className="meta-box">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={(props) => {
                                    return (<Tooltip {..._.omit(props, ['show'])}>MP4 audio file</Tooltip>)
                                }}
                            >
                            <span>mp4</span>
                            </OverlayTrigger>
                        </div>

                        <div className="meta-box">
                            <IconHoverTooltip labelText="Video recording"
                                              iconClass="fal fa-video" />
                        </div>

                        <div className="meta-box">
                            {participants_count === 1 ?
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={(props) => {
                                        return (<Tooltip {..._.omit(props, ['show'])}>Single listener</Tooltip>)
                                    }}
                                >
                                    <i className="fal fa-user"/>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={(props) => {
                                        return (<Tooltip {..._.omit(props, ['show'])}>Multiple listeners / conference call</Tooltip>)
                                    }}
                                >
                                    <i className="fal fa-users"/>
                                </OverlayTrigger>
                            }
                        </div>

                        <div className="meta-box">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={(props) => {
                                    return (<Tooltip {..._.omit(props, ['show'])}>{location_enabled ? 'Location enabled' : 'No location data'}</Tooltip>)
                                }}
                            >
                                <i className="fal fa-map-marked-alt"/>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>

                <Card className="recording-card video-card">
                    <Card.Body>

                        <div className="card-content">

                            <div className="recording-main">
                                <Row>


                                    <Col>
                                        <Card.Title>{subscription && (subscription.display_name || subscription.sip_username)}</Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted  text-weight-400">
                                            <span className="recording-duration">{secondsToDuration(data.duration)}</span>
                                            {data.file_name}
                                        </Card.Subtitle>
                                    </Col>

                                    <Col sm="4">
                                        <div className="recording-labels">
                                            <div className="">
                                                <i className="fal fa-clock"/> {formatTime(data.recorded_at, timezone)}
                                            </div>


                                        </div>
                                    </Col>
                                </Row>

                                <div className="video-player my-4">
                                    <ReactPlayer
                                      ref={this.playerRef}
                                      playing={!this.state.paused}
                                      controls={true}
                                      light={!this.state.played}
                                      volume={1}
                                      url={streamUrl}
                                      width={data && data.extra_data && data.extra_data.width ? data.extra_data.width : 640}
                                      height={data && data.extra_data && data.extra_data.height ? data.extra_data.height : 360}
                                      style={{
                                          backgroundColor: '#e6e6e6',
                                      }} />
                                </div>

                                <Card.Link href="javascript:;" onClick={this.handleDownload}>Download ({formatFileSize(data.file_size)})</Card.Link>
                                <Card.Link href="javascript:;" onClick={this.toggleParticipants.bind(this)}>Participants</Card.Link>
                                <Card.Link href="javascript:;" onClick={this.toggleLocationMap.bind(this)}>Location</Card.Link>
                                <Card.Link href="javascript:;"
                                            onClick={this.handleDelete}
                                           className="ml-5 text-danger">Delete</Card.Link>
                            </div>
                        </div>


                        <div
                            className={classnames({
                                "p-3": true,
                                "border-top": true,
                                "bg-whitesmoke": true,
                                "d-none": (!showDetailsScreen)
                            })}
                        >
                            <Row>
                                <Col md={6}>
                                    <RecordingParticipants data={data} />
                                </Col>
                                <Col md={6}>
                                    <LocationMap
                                        lat={data.latitude}
                                        lng={data.longitude}/>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}


export default VideoRow;
