import React from 'react'
import classnames from "classnames"

import _ from "lodash"

import Card from "react-bootstrap/Card";
import RecordingsStore from "../../common/stores/Recordings";
import moment from "moment-timezone";
import UserStore from "../../common/stores/User";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import bootbox from "bootbox";
import WaveSurfer from "wavesurfer.js";
import WaveSurferCursor from "wavesurfer.js/dist/plugin/wavesurfer.cursor"
import WaveSurferTimeline from "wavesurfer.js/dist/plugin/wavesurfer.timeline"
import WaveSurferRegions from "wavesurfer.js/dist/plugin/wavesurfer.regions"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";

import {
    formatTime,
    formatFileSize,
    secondsToDuration,
} from './../../common/lib/helper';

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import RecordingRegions from "./recording-regions";
import Notifications from "../../common/lib/Notifications";
import RecordingParticipants from './participants';
import IconHoverTooltip from '../../common/components/IconHoverTooltip';
import LocationMap from './location-map';




class RecordingRow extends React.Component {

    constructor(props) {
        super(props);

        this.handleDelete = this.handleDelete.bind(this)
        this.handleDownload = this.handleDownload.bind(this)
        this.handleZoom = this.handleZoom.bind(this)
        this.handleVolume = this.handleVolume.bind(this)

        this.prepareFile = this.prepareFile.bind(this)
        this.initWavesurfer = this.initWavesurfer.bind(this);
        this.pause = this.pause.bind(this)
        this.play = this.play.bind(this)

        this.audioRef  = React.createRef();
        this.canvasRef = React.createRef();
        this.zoomInputRef = React.createRef();
        this.volumeInputRef = React.createRef();

        // Prepare regions from parent

        this.state = {
            decryptingFile: false,
            drawingWaveform: false,
            finishedDrawingWaveform: false,
            muted: false,
            mediaUrl: null,
            regions: props.regions ? props.regions : [],
            showDetailsScreen: false,
            showPlayButton: true,
            streamUrl: null,
            volume: 1,
            zoom: 0,
        }
    }

    async handleDownload() {
        const {id} = this.props;
        const mediaData = await RecordingsStore.getDownloadUrl(id)

        if (!mediaData || !mediaData.stream_url) {
            // Some error occurred while decrypting the file maybe?
            Notifications.showNotific8('An unexpected error has occurred while preparing ' +
                'and decrypting the file. Please try again and contact support if the problem persists.',
                'danger', { closeInSeconds: 8 } );

            return;
        }

        const downloadUrl = mediaData.download_url;

        window.open(downloadUrl, '_blank')
    }

    async handleDownloadTrim(regionId) {
        const {id} = this.props;
        const region = this.wavesurfer.regions.list[regionId];

        // Prepare timings
        let start = Math.floor(region.start)
        let end   = Math.ceil(region.end)

        const mediaData = await RecordingsStore.getDownloadUrl(id)

        if (!mediaData || !mediaData.stream_url) {
            // Some error occurred while decrypting the file maybe?
            Notifications.showNotific8('An unexpected error has occurred while preparing ' +
                'and decrypting the file. Please try again and contact support if the problem persists.',
                'danger', { closeInSeconds: 8 } );

            return;
        }

        let downloadUrl = mediaData.download_url;

        downloadUrl = downloadUrl.replace('recordings/download', 'recordings/download/trim');

        downloadUrl = `${downloadUrl}&start=${start}&end=${end}`

        window.open(downloadUrl, '_blank')
    }

    async prepareFile() {
        const {
            id,
        } = this.props;

        const mediaData = await RecordingsStore.getDownloadUrl(id);

        if (!mediaData || !mediaData.stream_url) {
            // Some error occurred while decrypting the file maybe?
            Notifications.showNotific8('An unexpected error has occurred while preparing ' +
                'and decrypting the file. Please try again and contact support if the problem persists.',
                'danger', { closeInSeconds: 8 } );

            return;
        }

        // Set the state
        this.setState({
            mediaUrl: mediaData.download_url,
            streamUrl: mediaData.stream_url,
            showPlayButton: false,
        });

        return mediaData;
    }

    async initWavesurfer() {
        const {
            id,
            data,
            regions,
        } = this.props;

        let {
            mediaUrl,
            streamUrl,
        } = this.state;

        if (!streamUrl) {
            this.setState({
                decryptingFile: true,
                drawingWaveform: false,
            })

            const mediaData = await this.prepareFile();

            if (mediaData) {
                streamUrl = mediaData.stream_url;

                this.setState({
                    decryptingFile: false,
                    drawingWaveform: true,
                })

            }
        }

        // Final trap in case the streaming URL is not defined
        if (!streamUrl) {
            this.setState({
                decryptingFile: false,
                drawingWaveform: false,
            })
            return null;
        }

        this.wavesurfer = WaveSurfer.create({
            backend: 'MediaElement',
            container: document.getElementById('waveform-' + id),

            mediaControls: false,

            barHeight: 1, // the height of the wave
            /*barWidth: 1,
            barHeight: 0.75, // the height of the wave
            barGap: null, // the optional spacing between bars of the wave, if not provided will be calculated in legacy formatTime*/

            progressColor: '#383838',
            waveColor: '#9C9C9C',

            height: 100,
            interact: true,
            normalize: true,
            fillParent: true,
            pixelRatio: 1,


            plugins: [
                WaveSurferCursor.create({
                    showTime: true,
                    opacity: 1,
                    customShowTimeStyle: {
                        'background-color': '#11999e',
                        color: '#fff',
                        padding: '2px',
                        'font-size': '12px'
                    }
                }),

                WaveSurferTimeline.create({
                    container: document.getElementById('timeline-' + id)
                }),

                WaveSurferRegions.create({
                    regions: regions,
                    snapToGridInterval: 1,
                    maxRegions: 6,
                    loop: false,
                    dragSelection: {
                        slop: 5
                    }
                }),
            ]
        });

        this.wavesurfer.on('pause', () => {
            this.setState({
                paused: true,
            })
        });

        this.wavesurfer.on('stop', () => {
            this.setState({
                paused: true,
            })
        });

        this.wavesurfer.on('play', () => {

            this.setState({
                paused: false,
                volume: this.wavesurfer.getVolume() * 100,
            })
        });

        this.wavesurfer.on('waveform', async () => {
            this.setState({
                finishedDrawingWaveform: true,
            })
        });

        this.wavesurfer.on('waveform-ready', async () => {

            this.setState({
                drawingWaveform: false,
                finishedDrawingWaveform: true,
            })

            // Export PCM and upload to server
            const pcmData = await this.wavesurfer.exportPCM(1300, 10000, true)
            if (pcmData) {
                // Update recording

                await RecordingsStore.updatePcm(id, {
                    pcm_data: pcmData,
                })
            }
        })

        // Region events
        this.wavesurfer.on('region-created', (region) => {

            /*const {
                data,
            } = this.props;

            const {
                regions
            } = this.state;

            const index = _.findIndex(regions, {id: region.id})
            if (-1 === index) {
                regions.push({
                    id: region.id,
                    start: region.start,
                    end: region.end,
                })

                this.regionUpdate(region)
            }

            this.setState({
                regions
            })*/
        });

        this.wavesurfer.on('region-updated', (region) => {

            const {
                regions
            } = this.state;

            const index = _.findIndex(regions, {id: region.id})
            if (-1 !== index) {
                regions[index].start = region.start;
                regions[index].end = region.end;

            } else {
                regions.push({
                    id: region.id,
                    start: region.start,
                    end: region.end,
                })
            }

            this.setState({
                regions
            })
        });

        this.wavesurfer.on('region-update-end', (region) => {

            this.regionUpdate(region)
        });

        this.wavesurfer.on('region-removed', (region) => {

            const {
                data
            } = this.props;

            let {
                regions
            } = this.state;

            const index = _.findIndex(regions, {id: region.id})
            if (-1 !== index) {
                // Remove from state
                _.pullAt(regions, index);
            }

            this.setState({
                regions
            })


            // Delete from server too
            RecordingsStore.removeRegion(data.id, region.id).then(() => {})
        });


        // Do we have PCM data?
        let peaks = null;
        if (data && data.extra_data && data.extra_data.pcm_data) {
            peaks = data.extra_data.pcm_data;

            this.wavesurfer.load(streamUrl, peaks, false, data.duration)

            this.setState({
                drawingWaveform: false,

                // Let the component know that the waveform drawing has been finished
                finishedDrawingWaveform: true,
            })
        } else {

            this.wavesurfer.load(streamUrl)
        }

        // Start with big volume
        this.wavesurfer.setVolume(1)

        /*this.zoomInputRef.current.value = this.wavesurfer.params.minPxPerSec;
        this.zoomInputRef.current.min = 0; //this.wavesurfer.params.minPxPerSec;*/

        this.play();
    }

    handleDelete() {
        const {
            data
        } = this.props;

        const subscription = data.subscription.data;

        let title = (subscription.display_name ? subscription.display_name : subscription.sip_username );
        title += `&nbsp;&nbsp;<span style="font-weight: 300;">${data.file_name}</span> `;

        bootbox.confirm({
            title: title,
            message: "Are you sure you want to delete this recording?",
            callback: function(result) {
                if ( result ) {
                    this.props.onDelete(data.id)
                }

            }.bind(this),

            buttons: {
                cancel: {
                    label: 'Cancel',
                    className: 'btn-default'
                },
                confirm: {
                    label: 'Delete',
                    className: 'btn-danger'
                }
            }
        });
    }

    handleZoom(event) {
        let {
            value
        } = event.target;

        if (value<0) {
            value = 0;
        }

        this.setState({
            zoom: value,

        }, () => {
            this.wavesurfer && this.wavesurfer.zoom(Number(value))
        })
    }

    handleVolume(event) {
        let {
            value
        } = event.target;

        if (value<0) {
            value = 0;
        }

        if (value>100) {
            value = 100;
        }

        this.setState({
            volume: value,

        }, () => {
            this.wavesurfer && this.wavesurfer.setVolume(Number(value/100))
        })
    }

    handleMuteUnmute(event) {
        const {
            muted,
            volume,
        } = this.state;

        this.wavesurfer.setMute(!muted);

        // If unmuting, set the previous volume
        if (muted) {
            this.wavesurfer.setVolume(Number(volume/100));
        }

        this.setState({
            muted: !muted
        })
    }

    pause() {
        if (this.wavesurfer) {
            this.setState({
                paused: true,
                showPlayButton: true,
            }, () => {
                this.wavesurfer.pause();

                window.playingWavesurfer = null;
            })
        }
    }

    play() {
        if (window.playingWavesurfer) {
            window.playingWavesurfer.pause();
        }
        if (this.wavesurfer) {

            this.setState({
                paused: false,
                showPlayButton: false,
            }, () => {
                this.wavesurfer.play();

                window.playingWavesurfer = this.wavesurfer;
            })
        }
    }


    regionPlay(regionId) {
        if (this.wavesurfer.regions.list[regionId]) {

            /***
             * The code to start and stop region loop play is
             * disabled for now
             *
             * @todo: fix playLoop() UI bugs
             *
             * *
            // Stop all loops
            for (const sRegionId in this.wavesurfer.regions.list ) {
                // Remove playLoop()'s on 'out' event
                this.wavesurfer.regions.list[sRegionId].unAll()
            }

            this.wavesurfer.regions.list[regionId].playLoop()
             */
            this.wavesurfer.regions.list[regionId].play()
        }
    }

    regionRemove(regionId) {
        this.wavesurfer.regions.list[regionId] && this.wavesurfer.regions.list[regionId].remove();
    }

    regionUpdate(region) {
        const {
            data
        } = this.props;

        RecordingsStore.updateRegion(data.id, region.id, {
            region_start: Math.floor(region.start),
            region_end: Math.ceil(region.end),

            //admin_note: region.admin_note,
        }).then(() => {})
    }

    toggleParticipants() {
        const {showDetailsScreen} = this.state;

        this.setState({
            showDetailsScreen: !showDetailsScreen,
        })
    }

    toggleLocationMap() {
        const {showDetailsScreen} = this.state;

        this.setState({
            showDetailsScreen: !showDetailsScreen,
        })
    }

    render() {
        const {data} = this.props;
        const {
            decryptingFile,
            drawingWaveform,
            finishedDrawingWaveform,
            mediaUrl,
            muted,
            regions,
            showDetailsScreen,
            showPlayButton,
            streamUrl,
            volume,
            zoom,
        } = this.state;

        const subscription = data.subscription.data;

        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        const c = moment.unix(data.recorded_at).tz(timezone);

        let participants_count = 0;
        if (data.participants && data.participants.users) {
            participants_count = data.participants.users.length;
        }

        let location_enabled = !!data.latitude;

        return (
            <div className="recording-row">
                <div className="recording-meta">

                    <div className="meta-play">
                        <div className="d-flex justify-content-center align-items-center h-100">
                            {!this.wavesurfer ?
                              <button className="btn btn-warning waveform-player-button play"
                                      onClick={this.initWavesurfer}
                                      disabled={!!decryptingFile}
                              >
                                  <i className="fa fa-play"/>
                              </button>
                              :
                              this.state.paused ?
                                <button className="btn btn-warning waveform-player-button play"
                                        onClick={() => this.play()}
                                        disabled={!!decryptingFile}
                                >
                                    <i className="fa fa-play"/>
                                </button>
                                :
                                <button className="btn btn-warning waveform-player-button pause"
                                        onClick={() => this.pause()}
                                        disabled={!!decryptingFile}
                                >
                                    <i className="fa fa-pause"/>
                                </button>

                            }
                        </div>
                    </div>

                    <div className="meta-info">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={(props) => {
                              return (<Tooltip {..._.omit(props, ['show'])}>{formatTime(data.recorded_at, timezone)}</Tooltip>)
                          }}
                        >
                            <div className="meta-box full-width text-primary">
                                {c.format('D MMM YYYY')}
                            </div>
                        </OverlayTrigger>
                    </div>

                    <div className="meta-info">
                        <div className="meta-box">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={(props) => {
                                    return (<Tooltip {..._.omit(props, ['show'])}>MP3 audio file</Tooltip>)
                                }}
                            >
                            <span>mp3</span>
                            </OverlayTrigger>
                        </div>

                        <div className="meta-box">
                            <IconHoverTooltip labelText="Audio recording"
                                              iconClass="fal fa-microphone-alt" />
                        </div>

                        <div className="meta-box">
                            {participants_count === 1 ?
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={(props) => {
                                        return (<Tooltip {..._.omit(props, ['show'])}>Single listener</Tooltip>)
                                    }}
                                >
                                    <i className="fal fa-user"/>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={(props) => {
                                        return (<Tooltip {..._.omit(props, ['show'])}>Multiple listeners / conference call</Tooltip>)
                                    }}
                                >
                                    <i className="fal fa-users"/>
                                </OverlayTrigger>
                            }
                        </div>

                        <div className="meta-box">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={(props) => {
                                    return (<Tooltip {..._.omit(props, ['show'])}>{location_enabled ? 'Location enabled' : 'No location data'}</Tooltip>)
                                }}
                            >
                                <i className="fal fa-map-marked-alt"/>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>

                <Card className="recording-card">
                    <Card.Body>

                        <div className="card-content">

                            <div className="recording-main">
                                <Row>

                                    <Col>
                                        <Card.Title>{subscription && (subscription.display_name || subscription.sip_username)}</Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted  text-weight-400">
                                            <span className="recording-duration">{secondsToDuration(data.duration)}</span>
                                            {data.file_name}
                                        </Card.Subtitle>
                                    </Col>

                                    <Col sm="4">
                                        <div className="recording-labels">
                                            <div className="">
                                                <i className="fal fa-clock"/> {formatTime(data.recorded_at, timezone)}
                                            </div>


                                        </div>
                                    </Col>
                                </Row>

                                <div className="waveform-player-buttons">
                                    <Row>

                                    </Row>
                                </div>

                                <div className="waveform-container">
                                    {streamUrl || decryptingFile || drawingWaveform ? null :
                                        <div className="source-not-loaded" onClick={this.initWavesurfer}>
                                            <i className="fal fa-waveform-path"/>
                                            <span>Click to load the recording</span>
                                        </div>
                                    }
                                    {!decryptingFile ? null :
                                        <div className="source-not-loaded" style={{zIndex: 999}}>
                                            <i className="fal fa-cog fa-spin"/>
                                            <span>Decrypting the audio file</span>
                                        </div>
                                    }
                                    {!drawingWaveform ? null :
                                        <div className="source-not-loaded">
                                            <i className="fal fa-compact-disc fa-spin"/>
                                            <span>Rendering audio waveform, please wait..</span>
                                        </div>
                                    }
                                    <div id={`waveform-${data.id}`} className="waveform-audio-wave"/>
                                    <div id={`timeline-${data.id}`}/>
                                </div>

                                <div className={classnames('waveform-controls', !streamUrl && 'disabled')}>
                                    <div className="d-flex">
                                        <div className="control-icon icon-volume">
                                            <i className={classnames("fal", muted ? "fa-volume-mute" : "fa-volume")}
                                               onClick={this.handleMuteUnmute.bind(this)} />
                                        </div>

                                        <div className="control-input">
                                            <input ref={this.volumeInputRef}
                                                   value={volume}
                                                   className="waveform-zoom"
                                                   onChange={this.handleVolume}
                                                   data-action="zoom" type="range" min="1" max="100" step="1" />
                                        </div>

                                        <div style={{width: 150}}/>

                                        {/*<div className="control-icon">
                                            <i className="fal fa-search-plus align-middle"
                                               onClick={() => this.handleZoom({
                                                   target: {
                                                       value: 0
                                                   }
                                               })} />
                                        </div>

                                        <div className="control-input">
                                            <input ref={this.zoomInputRef}
                                                   value={zoom}
                                                    className="waveform-zoom"
                                                   onChange={this.handleZoom}
                                                   data-action="zoom" type="range" min="1" max="100" step="1" />
                                        </div>*/}
                                    </div>
                                </div>

                                {!finishedDrawingWaveform ? null :
                                <RecordingRegions
                                    className={"wavecontrol-regions border-top border-bottom"}
                                    regions={regions}
                                    onPlay={this.regionPlay.bind(this)}
                                    onPause={this.pause}
                                    onDownloadRegion={this.handleDownloadTrim.bind(this)}
                                    onRemoveRegion={this.regionRemove.bind(this)}
                                />
                                }

                                <Card.Link href="javascript:;" onClick={this.handleDownload}>Download ({formatFileSize(data.file_size)})</Card.Link>
                                <Card.Link href="javascript:;" onClick={this.toggleParticipants.bind(this)}>Participants</Card.Link>
                                <Card.Link href="javascript:;" onClick={this.toggleLocationMap.bind(this)}>Location</Card.Link>
                                <Card.Link href="javascript:;"
                                            onClick={this.handleDelete}
                                           className="ml-5 text-danger">Delete</Card.Link>
                            </div>
                        </div>


                        <div
                            className={classnames({
                                "p-3": true,
                                "border-top": true,
                                "bg-whitesmoke": true,
                                "d-none": (!showDetailsScreen)
                            })}
                        >
                            <Row>
                                <Col md={6}>
                                    <RecordingParticipants data={data} />
                                </Col>
                                <Col md={6}>
                                    <LocationMap
                                        lat={data.latitude}
                                        lng={data.longitude}/>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}


export default RecordingRow;
